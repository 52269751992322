import React, { useEffect } from "react";
import "../style/App.css";
import "../style/Portfolio.css";
import { motion } from "framer-motion";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ReactPlayer from "react-player";

export default function Portfolio() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(".hidden-text, .hidden");
    hiddenElements.forEach((el) => {
      observer.observe(el);
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0.7 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.35, ease: "easeOut" }}
    >
      <Navbar />
      <div className="portfolio" id="top">
        <h1 className="hidden-text">Portfolio</h1>
      </div>
      <div className="portfolio-gallery">
        <div className="portfolio-gallery-container">
          <div className="video-wrapper">
            <div className="video-card hidden">
              <div className="player-wrapper">
                <ReactPlayer
                  url="https://vimeo.com/391186649"
                  controls
                  className="react-player"
                  playsInline
                  width="100%"
                  height="100%"
                  light
                />
              </div>
            </div>
            <div className="nametag">
              <h2>Bestseller Empiku</h2>
            </div>
          </div>
          <div className="video-wrapper">
            <div className="video-card hidden">
              <div className="player-wrapper">
                <ReactPlayer
                  url="https://vimeo.com/209119951"
                  controls
                  className="react-player"
                  playsInline
                  width="100%"
                  height="100%"
                  light
                />
              </div>
            </div>
            <div className="nametag">
              <h2>Aktivist</h2>
            </div>
          </div>
          <div className="video-wrapper">
            <div className="video-card hidden">
              <div className="player-wrapper">
                <ReactPlayer
                  url="https://vimeo.com/391206014"
                  controls
                  className="react-player"
                  playsInline
                  width="100%"
                  height="100%"
                  light
                />
              </div>
            </div>
            <div className="nametag">
              <h2>Jägermeister</h2>
            </div>
          </div>
          <div className="video-wrapper">
            <div className="video-card hidden">
              <div className="player-wrapper">
                <ReactPlayer
                  url="https://vimeo.com/431586852"
                  controls
                  className="react-player"
                  playsInline
                  width="100%"
                  height="100%"
                  light
                />
              </div>
            </div>
            <div className="nametag">
              <h2>Pigment</h2>
            </div>
          </div>
          <div className="video-wrapper">
            <div className="video-card hidden">
              <div className="player-wrapper">
                <ReactPlayer
                  url="https://vimeo.com/395936067"
                  controls
                  className="react-player"
                  playsInline
                  width="100%"
                  height="100%"
                  light
                />
              </div>
            </div>
            <div className="nametag">
              <h2>Wokas</h2>
            </div>
          </div>
          <div className="video-wrapper">
            <div className="video-card hidden">
              <div className="player-wrapper">
                <ReactPlayer
                  url="https://vimeo.com/273678411"
                  controls
                  className="react-player"
                  playsInline
                  width="100%"
                  height="100%"
                  light
                />
              </div>
            </div>
            <div className="nametag">
              <h2>Fundacja Orange</h2>
            </div>
          </div>
          <div className="video-wrapper">
            <div className="video-card hidden">
              <div className="player-wrapper">
                <ReactPlayer
                  url="https://youtu.be/qPVTWJKQUpc"
                  controls
                  className="react-player"
                  playsInline
                  width="100%"
                  height="100%"
                  light
                />
              </div>
            </div>
            <div className="nametag">
              <h2>Ambasada Szwecji</h2>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}
