import React from "react";

export default function Icon8({ className }) {
  return (
    <svg
      className={className}
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="filled_outline" data-name="filled outline">
        <path d="m280 440h-48l8-32h32z" fill="#a8a8a8" />
        <path
          d="m256 416a128.145 128.145 0 0 1 -128-128v-72a8 8 0 0 1 16 0v72a112 112 0 0 0 224 0v-72a8 8 0 0 1 16 0v72a128.145 128.145 0 0 1 -128 128z"
          fill="#cbcbcb"
        />
        <path
          d="m168 216v-104a88 88 0 0 1 88-88 88 88 0 0 1 88 88v104z"
          fill="#0296e5"
        />
        <path
          d="m344 216v72a88 88 0 0 1 -88 88 88 88 0 0 1 -88-88v-72z"
          fill="#0276b4"
        />
        <path d="m168 248h176v40h-176z" fill="#cbcbcb" />
        <path
          d="m216 440h80a48 48 0 0 1 48 48 0 0 0 0 1 0 0h-176a0 0 0 0 1 0 0 48 48 0 0 1 48-48z"
          fill="#cbcbcb"
        />
        <path d="m120 184h32v32h-32z" fill="#a8a8a8" />
        <path d="m88 176h32v48h-32z" fill="#cbcbcb" />
        <path
          d="m360 184h32v32h-32z"
          fill="#a8a8a8"
          transform="matrix(-1 0 0 -1 752 400)"
        />
        <path
          d="m392 176h32v48h-32z"
          fill="#cbcbcb"
          transform="matrix(-1 0 0 -1 816 400)"
        />
        <g fill="#0276b4">
          <circle cx="256" cy="144" r="8" />
          <circle cx="256" cy="112" r="8" />
          <circle cx="256" cy="80" r="8" />
          <circle cx="256" cy="48" r="8" />
          <circle cx="288" cy="144" r="8" />
          <circle cx="288" cy="112" r="8" />
          <circle cx="288" cy="80" r="8" />
          <circle cx="288" cy="48" r="8" />
          <circle cx="320" cy="144" r="8" />
          <circle cx="320" cy="112" r="8" />
          <circle cx="320" cy="80" r="8" />
          <circle cx="224" cy="144" r="8" />
          <circle cx="224" cy="112" r="8" />
          <circle cx="224" cy="80" r="8" />
          <circle cx="224" cy="48" r="8" />
          <circle cx="192" cy="144" r="8" />
          <circle cx="192" cy="112" r="8" />
          <circle cx="192" cy="80" r="8" />
        </g>
        <path
          d="m288.007 341.963a8 8 0 0 1 -4.584-14.562 48.569 48.569 0 0 0 13.724-14.67 8 8 0 0 1 13.706 8.257 64.735 64.735 0 0 1 -18.276 19.536 7.966 7.966 0 0 1 -4.57 1.439z"
          fill="#0296e5"
        />
        <path
          d="m256 352a8 8 0 0 1 0-16 48.783 48.783 0 0 0 6.867-.486 8 8 0 1 1 2.266 15.839 64.835 64.835 0 0 1 -9.133.647z"
          fill="#0296e5"
        />
        <path
          d="m136.005 136a7.984 7.984 0 0 1 -6.252-3 72.034 72.034 0 0 1 0-90 8 8 0 0 1 12.494 10 55.722 55.722 0 0 0 0 70 8 8 0 0 1 -6.242 13z"
          fill="#fbb540"
        />
        <path
          d="m105.973 152a7.984 7.984 0 0 1 -6.078-2.793 94.05 94.05 0 0 1 0-122.414 8 8 0 0 1 12.148 10.414 78.047 78.047 0 0 0 0 101.586 8 8 0 0 1 -6.07 13.207z"
          fill="#fbb540"
        />
        <path
          d="m376 136a8 8 0 0 1 -6.242-13 55.722 55.722 0 0 0 0-70 8 8 0 0 1 12.494-10 72.034 72.034 0 0 1 0 90 7.984 7.984 0 0 1 -6.252 3z"
          fill="#fbb540"
        />
        <path
          d="m406.027 152a8 8 0 0 1 -6.07-13.207 78.047 78.047 0 0 0 0-101.586 8 8 0 0 1 12.148-10.414 94.05 94.05 0 0 1 0 122.414 7.984 7.984 0 0 1 -6.078 2.793z"
          fill="#fbb540"
        />
        <path d="m152 176h208v40h-208z" fill="#cbcbcb" />
        <path d="m168 216h176v16h-176z" fill="#015078" />
        <path d="m120 168h-32a8 8 0 0 0 -8 8v48a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v64c0 61.814 44.044 113.533 102.4 125.426l-4.646 18.574h-9.754a56.063 56.063 0 0 0 -56 56 8 8 0 0 0 8 8h176a8 8 0 0 0 8-8 56.063 56.063 0 0 0 -56-56h-9.754l-4.646-18.574c58.356-11.893 102.4-63.612 102.4-125.426v-64a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-48a8 8 0 0 0 -8-8h-32a8 8 0 0 0 -8 8h-16a8 8 0 0 0 -8-8h-8v-56a96 96 0 0 0 -192 0v56h-8a8 8 0 0 0 -8 8h-16a8 8 0 0 0 -8-8zm-24 48v-32h16v32zm239.2 264h-158.4a40.071 40.071 0 0 1 39.2-32h80a40.071 40.071 0 0 1 39.2 32zm-69.442-64 4 16h-27.512l4-16zm102.242-128a112 112 0 0 1 -224 0v-64h16v64a96 96 0 0 0 192 0v-64h16zm-32-64v16h-160v-16zm-160 56v-24h160v24zm159.6 16a80 80 0 0 1 -159.2 0zm80.4-112v32h-16v-32zm-32 8v16h-16v-16zm-208-80a80 80 0 0 1 160 0v56h-160zm176 72v24h-192v-24zm-208 8v16h-16v-16z" />
        <circle cx="256" cy="144" r="8" />
        <circle cx="256" cy="112" r="8" />
        <circle cx="256" cy="80" r="8" />
        <circle cx="256" cy="48" r="8" />
        <circle cx="288" cy="144" r="8" />
        <circle cx="288" cy="112" r="8" />
        <circle cx="288" cy="80" r="8" />
        <circle cx="288" cy="48" r="8" />
        <circle cx="320" cy="144" r="8" />
        <circle cx="320" cy="112" r="8" />
        <circle cx="320" cy="80" r="8" />
        <circle cx="224" cy="144" r="8" />
        <circle cx="224" cy="112" r="8" />
        <circle cx="224" cy="80" r="8" />
        <circle cx="224" cy="48" r="8" />
        <circle cx="192" cy="144" r="8" />
        <circle cx="192" cy="112" r="8" />
        <circle cx="192" cy="80" r="8" />
        <path d="m288.007 341.963a7.966 7.966 0 0 0 4.57-1.439 64.735 64.735 0 0 0 18.276-19.536 8 8 0 0 0 -13.706-8.257 48.569 48.569 0 0 1 -13.724 14.67 8 8 0 0 0 4.584 14.562z" />
        <path d="m256 352a64.835 64.835 0 0 0 9.133-.647 8 8 0 1 0 -2.266-15.839 48.783 48.783 0 0 1 -6.867.486 8 8 0 0 0 0 16z" />
        <path d="m136.005 136a8 8 0 0 0 6.242-13 55.722 55.722 0 0 1 0-70 8 8 0 0 0 -12.494-10 72.034 72.034 0 0 0 0 90 7.984 7.984 0 0 0 6.252 3z" />
        <path d="m105.973 152a8 8 0 0 0 6.07-13.207 78.047 78.047 0 0 1 0-101.586 8 8 0 0 0 -12.143-10.414 94.05 94.05 0 0 0 0 122.414 7.984 7.984 0 0 0 6.073 2.793z" />
        <path d="m371 134.247a8 8 0 0 0 11.247-1.247 72.034 72.034 0 0 0 0-90 8 8 0 0 0 -12.494 10 55.722 55.722 0 0 1 0 70 8 8 0 0 0 1.247 11.247z" />
        <path d="m400.825 150.074a8 8 0 0 0 11.28-.867 94.05 94.05 0 0 0 0-122.414 8 8 0 0 0 -12.148 10.414 78.047 78.047 0 0 1 0 101.586 8 8 0 0 0 .868 11.281z" />
      </g>
    </svg>
  );
}
