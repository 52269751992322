import { useState, useEffect } from "react";
import "../style/App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Onas from "./Onas";
import Portfolio from "./Portfolio";
import Kontakt from "./Kontakt";
import CircleLoader from "react-spinners/CircleLoader";

export default function App() {
  const [isLoading, setLoading] = useState(true);

  function mockupRequest() {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  }

  useEffect(() => {
    mockupRequest().then(() => {
      setLoading(!isLoading);
    });
  }, []);

  if (isLoading) {
    return (
      <div className="loading">
        <CircleLoader
          color={"white"}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/o-nas" element={<Onas />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </HashRouter>
    </div>
  );
}
