import React, { useEffect } from "react";
import "../style/Section2.css";
import "../style/App.css";
import "../style/cool-border.scss";
import Icon1 from "../Components/Icon1";
import Icon2 from "../Components/Icon2";
import Icon3 from "../Components/Icon3";
import Icon4 from "../Components/Icon4";
import Icon5 from "../Components/Icon5";
import Icon6 from "../Components/Icon6";
import Icon7 from "../Components/Icon7";
import Icon8 from "../Components/Icon8";
import Icon9 from "../Components/Icon9";

export default function Clients() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(
      ".hidden, .hidden-offer, .hidden-icons"
    );
    hiddenElements.forEach((el) => {
      observer.observe(el);
    });
  }, []);

  return (
    <>
      <div className="o-nas-section--oferta-container">
        <p className="hidden opening-paragraph cool-border-1">
          W Kajutex Studio tworzymy produkcje dla wszystkich mediów. Działamy
          w&nbsp;telewizji, internecie, kinie i&nbsp;radiu, przeprowadzamy
          również sesje fotograficzne, więc WSZYSTKIE BAZY OBSTAWIONE!
        </p>

        <div className="o-nas-section--oferta-wrapper">
          <img
            className="hidden kaja-photo"
            src={require("../assets/kaja2.jpg")}
            alt=""
          />
          <p className="hidden opening-paragraph-3">
            Kajutex to nie firma z&nbsp;lat 90. tylko pseudonim Kai Klimek,
            krytyczki filmowej i&nbsp;popkulturalnej, założycielki Kajutex
            Studio. Kaja współpracuje między innymi z&nbsp;DZIEŃ DOBRY TVN,
            kanałem TVN FABUŁA, dystrybutorem filmowym GUTEK FILM
            i&nbsp;festiwalami filmowymi takimi jak MFF NOWE HORYZONTY
            we&nbsp;Wrocławiu czy MDAG w&nbsp;Warszawie (i&nbsp;całej Polsce).
          </p>
        </div>
      </div>
      <div className="section-2">
        <div className="section-2--container">
          <div className="o-nas-section--oferta">
            <h2 className="hidden o-nas--h2">Nasza oferta:</h2>

            <div className="oferta-list">
              <div className="oferta-list-row">
                <div className="oferta-list-item">
                  <Icon6 className="oferta-icons hidden-icons" />
                  <p className="oferta-text hidden-offer">Materiały wideo</p>
                </div>
                <div className="oferta-list-item">
                  <Icon2 className="oferta-icons hidden-icons" />
                  <p className="oferta-text hidden-offer">
                    Postprodukcja obrazu
                  </p>
                </div>
                <div className="oferta-list-item">
                  <Icon3 className="oferta-icons hidden-icons" />
                  <p className="oferta-text hidden-offer">Sesje foto</p>
                </div>
              </div>
              <div className="oferta-list-row">
                <div className="oferta-list-item">
                  <Icon5 className="oferta-icons hidden-icons" />
                  <p className="oferta-text hidden-offer">
                    Programy TV i internetowe
                  </p>
                </div>

                <div className="oferta-list-item">
                  <Icon4 className="oferta-icons hidden-icons" />
                  <p className="oferta-text hidden-offer">
                    Konsultacje scenariuszowe
                  </p>
                </div>
                <div className="oferta-list-item">
                  <Icon1 className="oferta-icons hidden-icons" />
                  <p className="oferta-text hidden-offer">Filmy korporacyjne</p>
                </div>
              </div>
              <div className="oferta-list-row">
                <div className="oferta-list-item">
                  <Icon7 className="oferta-icons hidden-icons" />
                  <p className="oferta-text hidden-offer">Packshoty</p>
                </div>
                <div className="oferta-list-item">
                  <Icon8 className="oferta-icons hidden-icons" />
                  <p className="oferta-text hidden-offer">
                    Realizacja wywiadów
                  </p>
                </div>
                <div className="oferta-list-item">
                  <Icon9 className="oferta-icons hidden-icons" />
                  <p className="oferta-text hidden-offer">
                    Relacje z premier i wydarzeń
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
