import React from "react";

export default function LocationIcon({ className }) {
  return (
    <svg
      className={className}
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="m406.5 249.182c-50.72.71-92.11 42.1-92.81 92.82-.28 20.03 5.69 38.64 16.09 54.02l56.96 88.73c9.86 15.37 32.34 15.37 42.2 0l56.96-88.73c10.468-15.496 16.1-33.982 16.1-52.68 0-52.45-42.88-94.89-95.5-94.16zm1.34 135.12c-22.69 0-41.07-18.38-41.07-41.07 0-22.68 18.38-41.07 41.07-41.07 22.68 0 41.07 18.39 41.07 41.07 0 22.69-18.39 41.07-41.07 41.07z"
            fill="#ffce62"
          />
        </g>
        <g>
          <path
            d="m407.84 174.132v74.94c-.45 0-.9 0-1.35.01-50.71.7-92.1 42.1-92.81 92.82-.21 15.11 3.14 29.42 9.27 42.14h-269.1v-210.319l176.99-101.211z"
            fill="#fff"
          />
        </g>
        <g>
          <path d="m195.188 264.991h71.315v119.054h-71.315z" fill="#e0a083" />
        </g>
        <g>
          <g>
            <path
              d="m451.688 141.488v57.816l-220.838-126.785-220.85 126.785v-57.816l220.85-125.766z"
              fill="#ff7d95"
            />
          </g>
          <g>
            <path d="m89.158 96.411v-80.689h51.69v51.253z" fill="#ff7d95" />
          </g>
        </g>
        <g>
          <circle cx="230.844" cy="173.723" fill="#b2daff" r="31.082" />
        </g>
        <g>
          <path d="m480.973 269.169c-17.253-17.015-39.357-27.267-63.133-29.517v-48.25l28.87 16.575c6.532 3.752 14.979-1.136 14.979-8.672v-57.816c0-3.594-1.929-6.911-5.052-8.689l-71.399-40.662c-4.798-2.732-10.906-1.059-13.639 3.741-2.733 4.799-1.059 10.904 3.74 13.638l66.35 37.786v34.73l-205.87-118.192c-3.079-1.768-6.862-1.771-9.943-.009l-205.876 118.201v-34.731l210.851-120.073 68.217 38.85c4.801 2.733 10.906 1.06 13.639-3.741 2.733-4.799 1.059-10.904-3.74-13.638l-73.167-41.668c-3.067-1.748-6.83-1.748-9.897 0l-75.055 42.741v-34.052c0-5.522-4.478-10-10-10h-51.69c-5.522 0-10 4.478-10 10v74.876l-74.106 42.202c-3.123 1.778-5.052 5.095-5.052 8.689v57.816c0 3.568 1.901 6.866 4.989 8.654 3.088 1.789 6.896 1.794 9.989.019l28.871-16.574v192.639c0 5.522 4.478 10 10 10h262.99c1.422 2.545 2.944 5.044 4.588 7.48l56.896 88.629c6.479 10.099 17.513 16.128 29.518 16.128 12.004 0 23.038-6.029 29.515-16.125l56.899-88.635c11.535-17.121 17.745-37.534 17.745-58.176 0-28.1-11.019-54.442-31.027-74.174zm-381.815-243.448h31.689v35.44l-31.689 18.047zm157.344 348.321h-51.314v-99.051h51.314zm20 0v-109.051c0-5.522-4.478-10-10-10h-71.314c-5.522 0-10 4.478-10 10v109.051h-121.338v-194.12l167.001-95.872 166.989 95.873v59.624c-23.95 2.29-46.269 12.808-63.576 30.117-19.341 19.345-30.202 44.949-30.583 72.1-.152 10.909 1.394 21.85 4.604 32.278zm211.708-5.648c-2.477 7.972-6.232 15.225-10.725 22.226l-56.962 88.732c-2.824 4.402-7.447 6.927-12.683 6.927s-9.859-2.524-12.686-6.93l-56.96-88.729c-9.349-14.66-14.75-30.995-14.506-48.48.618-44.702 38.19-82.332 82.95-82.958 46.34-.7 85.362 37.844 85.362 84.161 0 8.552-1.274 16.978-3.79 25.051z" />
          <path d="m407.84 292.162c-28.16 0-51.069 22.91-51.069 51.07s22.909 51.07 51.069 51.07 51.07-22.91 51.07-51.07-22.91-51.07-51.07-51.07zm0 82.14c-17.132 0-31.069-13.938-31.069-31.07s13.938-31.07 31.069-31.07 31.07 13.938 31.07 31.07-13.938 31.07-31.07 31.07z" />
          <path d="m230.844 132.64c-22.652 0-41.082 18.43-41.082 41.082 0 22.653 18.43 41.083 41.082 41.083 22.653 0 41.083-18.43 41.083-41.083 0-22.652-18.43-41.082-41.083-41.082zm0 62.165c-11.625 0-21.082-9.458-21.082-21.083s9.457-21.082 21.082-21.082 21.083 9.457 21.083 21.082-9.458 21.083-21.083 21.083z" />
          <path d="m333.88 83.48c2.095 5.034 8.005 7.479 13.05 5.41 5.039-2.067 7.493-8.042 5.41-13.06-2.089-5.033-8.019-7.498-13.06-5.41-5.049 2.091-7.467 8.023-5.4 13.06z" />
        </g>
      </g>
    </svg>
  );
}
