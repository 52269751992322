import "../style/Hero.css";
import '../style/App.css';
import React, { useEffect } from "react";

export default function Hero() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(".hidden-header");
    hiddenElements.forEach((el) => {
      observer.observe(el);
    });
  }, []);
  return (
    <div className="hero-container" id="top">
      <video
        className="hero-video"
        src={require("../assets/film_strona.mp4")}
        poster={require('../assets/poster-0.png')}
        autoPlay
        loop
        muted
        playsInline
        disableRemotePlayback
        disablePictureInPicture
      />
      <h2 className="hidden-header">We do create.</h2>
    </div>
  );
}
