import React from "react";
import "../style/App.css";
import Hero from "./Hero";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from './Section3';
import { motion } from "framer-motion";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default function Home() {
  return (
    <motion.div
      initial={{ opacity: 0.7 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.35, ease: "easeOut" }}
    >
      <Navbar />
      <Hero />
      <Section2 />
      <Section1 />
      <Section3 />
      <Footer />
    </motion.div>
  );
}
