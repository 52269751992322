import React from "react";

export default function Icon6({ className }) {
  return (
    <svg viewBox="0 -16 512.001 512" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="m266.25 10c-38.660156 0-70 31.339844-70 70s31.339844 70 70 70 70-31.339844 70-70-31.339844-70-70-70zm0 0"
        fill="#f6d465"
      />
      <path
        d="m126.25 10c-38.660156 0-70 31.339844-70 70s31.339844 70 70 70 70-31.339844 70-70-31.339844-70-70-70zm0 0"
        fill="#f6d465"
      />
      <path d="m502.25 130v240l-86-60v-120zm0 0" fill="#3a5361" />
      <path d="m376.25 190h40v120h-40zm0 0" fill="#fff" />
      <path
        d="m376.25 350c0 11.050781-8.949219 20-20 20-9.457031 0-317.867188 0-326 0-11.050781 0-20-8.949219-20-20v-180c0-11.050781 8.949219-20 20-20h326c11.050781 0 20 8.949219 20 20zm0 0"
        fill="#3a5361"
      />
      <path
        d="m296.25 370v40c-25.261719 0-176.261719 0-200 0v-40zm0 0"
        fill="#fff"
      />
      <path
        d="m266.25 60c11.03125 0 20 8.960938 20 20s-8.96875 20-20 20-20-8.960938-20-20 8.96875-20 20-20zm0 0"
        fill="#3a5361"
      />
      <path d="m136.25 410h120v60h-120zm0 0" fill="#3a5361" />
      <path d="m76.25 210h140v60h-140zm0 0" fill="#fff" />
      <path
        d="m126.25 60c11.03125 0 20 8.960938 20 20s-8.96875 20-20 20-20-8.960938-20-20 8.96875-20 20-20zm0 0"
        fill="#3a5361"
      />
      <path d="m316 200c-5.519531 0-10 4.480469-10 10s4.480469 10 10 10 10-4.480469 10-10-4.480469-10-10-10zm0 0" />
      <path d="m316 260c-5.519531 0-10 4.480469-10 10s4.480469 10 10 10 10-4.480469 10-10-4.480469-10-10-10zm0 0" />
      <path d="m76 300c-5.519531 0-10 4.480469-10 10s4.480469 10 10 10 10-4.480469 10-10-4.480469-10-10-10zm0 0" />
      <path d="m506.625 121.132812c-3.304688-1.722656-7.289062-1.464843-10.347656.667969l-83.421875 58.199219h-26.855469v-10c0-16.542969-13.457031-30-30-30h-37.167969c16.640625-14.671875 27.167969-36.121094 27.167969-60 0-44.113281-35.886719-80-80-80-30.089844 0-56.34375 16.707031-70 41.324219-13.65625-24.617188-39.910156-41.324219-70-41.324219-44.113281 0-80 35.886719-80 80 0 23.878906 10.527344 45.328125 27.167969 60h-43.167969c-16.542969 0-30 13.457031-30 30v180c0 16.542969 13.457031 30 30 30h56v30c0 5.523438 4.476562 10 10 10h30v50c0 5.523438 4.476562 10 10 10h120c5.523438 0 10-4.476562 10-10v-50h30c5.523438 0 10-4.476562 10-10v-30h50c16.542969 0 30-13.457031 30-30v-30h26.855469l83.421875 58.203125c3.023437 2.105469 7.007812 2.40625 10.347656.664063 3.304688-1.726563 5.375-5.140626 5.375-8.867188v-240c0-3.726562-2.070312-7.140625-5.375-8.867188zm-240.625-101.132812c33.085938 0 60 26.917969 60 60 0 33.085938-26.914062 60-60 60s-60-26.914062-60-60c0-33.082031 26.914062-60 60-60zm-52.832031 120h-34.339844c6.871094-6.054688 12.699219-13.261719 17.171875-21.324219 4.472656 8.0625 10.300781 15.269531 17.167969 21.324219zm-147.167969-60c0-33.082031 26.914062-60 60-60s60 26.917969 60 60c0 33.085938-26.914062 60-60 60s-60-26.914062-60-60zm180 380h-100v-40h100zm40-60h-180v-20h180zm80-50c0 5.515625-4.484375 10-10 10h-326c-5.515625 0-10-4.484375-10-10v-180c0-5.511719 4.484375-10 10-10h326c5.515625 0 10 4.488281 10 10zm20-150h20v100h-20zm106 150.832031-66-46.046875v-109.566406l66-46.046875zm0 0" />
      <path d="m126 50c-16.542969 0-30 13.457031-30 30s13.457031 30 30 30 30-13.457031 30-30-13.457031-30-30-30zm0 40c-5.515625 0-10-4.484375-10-10 0-5.511719 4.484375-10 10-10s10 4.488281 10 10c0 5.515625-4.484375 10-10 10zm0 0" />
      <path d="m266 110c16.542969 0 30-13.457031 30-30s-13.457031-30-30-30-30 13.457031-30 30 13.457031 30 30 30zm0-40c5.515625 0 10 4.488281 10 10 0 5.515625-4.484375 10-10 10s-10-4.484375-10-10c0-5.511719 4.484375-10 10-10zm0 0" />
      <path d="m226 270v-60c0-5.523438-4.476562-10-10-10h-140c-5.523438 0-10 4.476562-10 10v60c0 5.523438 4.476562 10 10 10h140c5.523438 0 10-4.476562 10-10zm-20-10h-120v-40h120zm0 0" />
      <path d="m316 300h-200c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h200c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10zm0 0" />
    </svg>
  );
}
