import React from "react";

export default function Icon5({ className }) {
  return (
    <svg
      className={className}
      id="Capa_1"
      enableBackground="new 0 0 512.002 512.002"
      height="512"
      viewBox="0 0 512.002 512.002"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="m477.419 437.934 4.604-43.762c7.582-72.071 7.607-144.738.075-216.815l-4.678-44.768s-96.57-18.795-220.101-18.795-220.101 18.794-220.101 18.794l-.033.191c-16.974 99.415-17.144 200.965-.606 300.453l.639 4.7s96.57 19.809 220.101 19.809 220.1-19.807 220.1-19.807z"
            fill="#ff7e96"
          />
        </g>
        <g>
          <path
            d="m319.742 401.345c12.833-2.036 23.178-11.654 26.059-24.324 8.554-37.619 8.553-92.112 8.553-92.112s0-54.493-8.554-92.112c-2.881-12.67-13.226-22.288-26.059-24.324-71.54-11.35-144.894-11.35-216.434 0-12.833 2.036-23.178 11.654-26.059 24.324-8.554 37.619-8.554 92.112-8.554 92.112s0 54.493 8.554 92.112c2.881 12.67 13.226 22.288 26.059 24.324 22.382 3.551 62.054 8.543 108.217 8.543 46.165 0 85.836-4.992 108.218-8.543z"
            fill="#bedaff"
          />
        </g>
        <g>
          <circle cx="419.512" cy="219.752" fill="#e0e7ef" r="26.523" />
        </g>
        <g>
          <path
            d="m442.429 443.892-9.874 40.983c-2.421 10.047-11.409 17.127-21.744 17.127h-307.96c-10.334 0-19.322-7.08-21.743-17.126l-9.914-41.137c38.663 5.844 106.837 14 186.12 14 78.603.001 146.28-8.013 185.115-13.847z"
            fill="#e0e7ef"
          />
        </g>
        <g>
          <path
            d="m206.995 111.148v3.662c-30.425 1.197-58.262 3.358-82.205 5.772v-9.435c0-22.704 18.403-41.107 41.107-41.107 22.421 0 41.098 18.69 41.098 41.108z"
            fill="#e0e7ef"
          />
        </g>
        <g>
          <path d="m492.044 176.317-4.679-44.769c-.458-4.375-3.72-7.937-8.037-8.776-1.023-.199-25.531-4.931-65.822-9.672-5.479-.642-10.455 3.278-11.101 8.763s3.278 10.455 8.764 11.1c26.978 3.174 47.021 6.397 57.085 8.135l3.897 37.297c7.445 71.244 7.421 143.489-.074 214.729l-3.825 36.364c-69.389 12.585-140.467 18.25-210.938 18.25-70.567 0-141.748-5.67-211.226-18.304-15.686-95.785-15.738-192.714-.167-288.253 69.62-12.021 140.792-17.39 211.397-17.39 21.754 0 44.19.598 66.686 1.776 5.52.288 10.222-3.948 10.51-9.463.289-5.515-3.948-10.22-9.463-10.509-22.843-1.196-45.631-1.803-67.732-1.803-13.952 0-27.576.243-40.778.672-1.464-11.008-6.516-21.247-14.615-29.348-2.676-2.678-5.593-5.011-8.687-7.005l103.236-49.079c4.987-2.371 7.108-8.337 4.737-13.325s-8.337-7.109-13.325-4.738l-121.883 57.946-115.756-57.857c-4.939-2.47-10.946-.466-13.416 4.474-2.469 4.94-.466 10.946 4.475 13.416l97.641 48.803c-14.49 9.031-24.158 25.103-24.158 43.397v.459c-48.299 5.185-78.94 11.06-79.482 11.166-4.073.793-7.233 4.019-7.942 8.107-8.913 51.954-13.28 104.698-13.073 157.41.2 50.91 6.184 100.656 13.017 150.991.572 4.208 3.739 7.597 7.899 8.45.309.063 10.352 2.093 27.827 4.837l8.351 34.653c3.517 14.592 16.455 24.783 31.465 24.783h307.961c15.011 0 27.95-10.192 31.465-24.785l8.31-34.493c18.1-2.823 28.527-4.931 28.842-4.995 4.269-.875 7.479-4.416 7.936-8.75l4.604-43.761c7.638-72.626 7.663-146.275.074-218.903zm-357.213-66.752c.827-16.42 14.445-29.525 31.067-29.525 14.851 0 27.671 11.057 30.512 25.218-20.553.968-41.092 2.409-61.579 4.307zm294.421 346.324-6.419 26.645c-1.343 5.575-6.286 9.469-12.021 9.469h-307.961c-5.734 0-10.678-3.894-12.021-9.469l-6.454-26.783c57.266 7.917 115.13 11.992 172.943 11.992 57.474 0 114.997-4.033 171.933-11.854z" />
          <path d="m211.525 149.93c-45.589 0-84.864 4.712-109.784 8.666-16.997 2.697-30.438 15.251-34.242 31.983-8.703 38.274-8.803 92.059-8.803 94.33s.1 56.056 8.803 94.33c3.805 16.732 17.245 29.286 34.243 31.983 14.392 2.283 37.344 5.382 64.955 7.181 5.508.348 10.271-3.818 10.629-9.329.359-5.511-3.817-10.27-9.328-10.629-26.851-1.749-49.146-4.758-63.122-6.976-8.877-1.408-15.894-7.95-17.875-16.665-8.199-36.062-8.305-89.362-8.305-89.895 0-.534.095-53.789 8.305-89.896 1.981-8.715 8.998-15.256 17.874-16.665 70.512-11.186 142.789-11.187 213.301 0 8.877 1.409 15.894 7.95 17.875 16.665 8.2 36.062 8.305 89.362 8.305 89.894 0 .534-.104 53.833-8.305 89.896-1.981 8.715-8.998 15.256-17.875 16.665-14.064 2.232-36.508 5.258-63.555 7.003-5.512.356-9.691 5.112-9.336 10.624.342 5.292 4.74 9.356 9.97 9.356.217 0 .435-.007.653-.021 27.814-1.795 50.918-4.911 65.402-7.209 16.997-2.697 30.438-15.251 34.242-31.983 8.703-38.274 8.803-92.059 8.803-94.33s-.1-56.056-8.803-94.33c-3.805-16.732-17.245-29.286-34.243-31.983-24.919-3.952-64.194-8.665-109.784-8.665z" />
          <path d="m392.121 303.05h56.812c5.522 0 10-4.477 10-10s-4.478-10-10-10h-56.812c-5.522 0-10 4.477-10 10s4.477 10 10 10z" />
          <path d="m391.106 345.659h56.812c5.522 0 10-4.477 10-10s-4.478-10-10-10h-56.812c-5.522 0-10 4.477-10 10s4.477 10 10 10z" />
          <path d="m387.047 388.268h56.812c5.522 0 10-4.477 10-10s-4.478-10-10-10h-56.812c-5.522 0-10 4.477-10 10s4.478 10 10 10z" />
          <path d="m419.511 256.275c20.139 0 36.523-16.384 36.523-36.523s-16.385-36.522-36.523-36.522-36.522 16.384-36.522 36.522 16.383 36.523 36.522 36.523zm0-53.046c9.111 0 16.523 7.412 16.523 16.522 0 9.111-7.412 16.523-16.523 16.523-9.11 0-16.522-7.412-16.522-16.523 0-9.109 7.412-16.522 16.522-16.522z" />
          <path d="m359.161 122.412c2.065 5.04 8.041 7.494 13.061 5.41 5.035-2.09 7.495-8.022 5.409-13.06-2.085-5.037-8.034-7.487-13.06-5.4-5.019 2.085-7.51 8.016-5.41 13.05z" />
          <path d="m219.84 404.332c-2.411-3.525-6.848-5.228-10.983-4.075-4.202 1.171-7.215 5.022-7.33 9.389-.235 8.903 11.047 13.625 17.215 7.158 3.22-3.376 3.649-8.577 1.098-12.472z" />
        </g>
      </g>
    </svg>
  );
}
