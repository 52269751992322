import React from "react";
import "../style/Section1.css";
import "../style/App.css";
import { useEffect } from "react";

export default function Section() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => {
      observer.observe(el);
    });
    const hiddenImages = document.querySelectorAll(".hidden-images");
    hiddenImages.forEach((el) => {
      observer.observe(el);
    });
  }, []);

  return (
    <div className="section-1">
      <div className="section-1--container">
        <h2 className="hidden">
          Zaufali nam m.in.:
        </h2>
        <div className="logos">
          <div className="logos-item-row">
            <img
              className="hidden-images"
              src={require("../assets/logo-1.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-2.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-3.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-4.png")}
              alt=""
            />
          </div>
          <div className="logos-item-row">
            <img
              className="hidden-images"
              src={require("../assets/logo-5.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-6.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-7.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-8.png")}
              alt=""
            />
          </div>
          <div className="logos-item-row">
            <img
              className="hidden-images"
              src={require("../assets/logo-9.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-10.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-11.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-12.png")}
              alt=""
            />
          </div>
          <div className="logos-item-row">
            <img
              className="hidden-images"
              src={require("../assets/logo-13.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-14.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-15.png")}
              alt=""
            />

            <img
              className="hidden-images"
              src={require("../assets/logo-16.png")}
              alt=""
            />
          </div>
          <div className="logos-item-row">
            <img
              className="hidden-images"
              src={require("../assets/logo-17.png")}
              alt=""
            />
            <img
              className="hidden-images"
              src={require("../assets/logo-18.png")}
              alt=""
            />
            <img
              className="hidden-images"
              src={require("../assets/logo-19.png")}
              alt=""
            />
            <img
              className="hidden-images"
              src={require("../assets/logo-20.png")}
              alt=""
            />
            </div>
            <div className="logos-item-row">
            <img
              className="hidden-images"
              src={require("../assets/logo-21.png")}
              alt=""
            />
            <img
              className="hidden-images"
              src={require("../assets/logo-22.png")}
              alt=""
            />
            <img
              className="hidden-images"
              src={require("../assets/logo-23.png")}
              alt=""
            />
            <img
              className="hidden-images"
              src={require("../assets/logo-24.png")}
              alt=""
            />
            </div>
          </div>
        </div>
      </div>
    
  );
}
