import React, { useState } from "react";
import "../style/Navbar.css";
import { HashLink as Link } from "react-router-hash-link";

export default function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  let prevScrollPos = window.scrollY;

  window.onscroll = () => {
    const navbar = document.querySelector(".navbar");
    const logo = document.querySelector(".navbar-logo");
    const links = document.querySelector(".nav-links");
    const menuIcon = document.querySelector(".menu-icon");
    const icon = document.getElementById("icon");
    let currentScrollPos = window.scrollY;
    if (window.scrollY > 600) {
      navbar.classList.add("scrolled");
      logo.classList.add("scrolled");
      links.classList.add("scrolled");
      icon.classList.add("scrolled");
      navbar.style.top = "-120px";
      menuIcon.style.top = "-120px";
    } else {
      navbar.classList.remove("scrolled");
      logo.classList.remove("scrolled");
      links.classList.remove("scrolled");
      icon.classList.remove("scrolled");
      navbar.style.top = "0";
      menuIcon.style.top = "0";
    }
    if (prevScrollPos > currentScrollPos) {
      navbar.style.top = "0";
      menuIcon.style.top = "0";
    }

    prevScrollPos = currentScrollPos;
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/#top">
          <img
            className="navbar-logo"
            src={require("../assets/logo.png")}
            alt="MasterClass Film"
          />
        </Link>

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/o-nas#top"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              O NAS
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/portfolio#top"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              PORTFOLIO
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/kontakt#top"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              KONTAKT
            </Link>
          </li>
        </ul>
      </div>
      <div className="menu-icon" onClick={handleClick}>
        <i
          id="icon"
          className={click ? "fa-solid fa-xmark" : "fa-solid fa-bars"}
        />
      </div>
    </nav>
  );
}
