import React from "react";

export default function Icon3({ className }) {
  return (
    <svg
      className={className}
      id="Layer_5"
      enableBackground="new 0 0 62 62"
      height="512"
      viewBox="0 0 62 62"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <path
              d="m61 37v6c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-1-4-1c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z"
              fill="#aab2bd"
            />
          </g>
          <g>
            <path d="m53 38h4v4h-4z" fill="#ccd1d9" />
          </g>
          <g>
            <path
              d="m58.87 58.69c.08.2.13.43.13.65 0 .92-.74 1.66-1.66 1.66h-1.28c-.65 0-1.23-.38-1.5-.96l-5.56-12.04v-3h4z"
              fill="#b27946"
            />
          </g>
          <g>
            <path
              d="m49 48v11.34c0 .92-.74 1.66-1.66 1.66h-.68c-.92 0-1.66-.74-1.66-1.66v-11.34-3h4z"
              fill="#b27946"
            />
          </g>
          <g>
            <path
              d="m45 45v3l-5.56 12.04c-.27.58-.85.96-1.5.96h-1.28c-.92 0-1.66-.74-1.66-1.66 0-.22.05-.45.13-.65l5.87-13.69z"
              fill="#b27946"
            />
          </g>
          <g>
            <path d="m53 42v3h-4-4-4v-10h4 4 4v3z" fill="#cf9e76" />
          </g>
          <g>
            <path d="m45 31h4v4h-4z" fill="#ccd1d9" />
          </g>
          <g>
            <path
              d="m51 1h-16v4l2.66.44c1.93.32 3.34 1.99 3.34 3.95v19.61c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-26c0-1.1-.9-2-2-2z"
              fill="#969faa"
            />
          </g>
          <g>
            <path
              d="m53 25v2h-7c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h7z"
              fill="#ccd1d9"
            />
          </g>
          <g>
            <path
              d="m53 17v2h-7c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2.53c1.1 1.23 2.7 2 4.47 2z"
              fill="#ccd1d9"
            />
          </g>
          <g>
            <path d="m41 11v16l-4 2h-8v-20h8z" fill="#aab2bd" />
          </g>
          <g>
            <path d="m15 9-4-2h-10v24h10l4-2h6v-20z" fill="#aab2bd" />
          </g>
          <g>
            <path d="m21 9h8v20h-8z" fill="#ccd1d9" />
          </g>
          <g>
            <path
              d="m53 5c3.31 0 6 2.69 6 6 0 1.77-.77 3.37-2 4.47-1.06.95-2.46 1.53-4 1.53-1.77 0-3.37-.77-4.47-2-.95-1.06-1.53-2.46-1.53-4 0-3.31 2.69-6 6-6z"
              fill="#fcd770"
            />
          </g>
          <g>
            <path
              d="m57 15.47v7.53l-4 2v-2-4-2c1.54 0 2.94-.58 4-1.53z"
              fill="#838f9b"
            />
          </g>
          <g>
            <circle cx="53" cy="11" fill="#ffeaa7" r="2" />
          </g>
        </g>
        <g>
          <path d="m32 12h2v4h-2z" />
          <path d="m32 22h2v4h-2z" />
          <path d="m50 11c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zm4 0c0 .551-.448 1-1 1s-1-.449-1-1 .448-1 1-1 1 .449 1 1z" />
          <path d="m60 35h-2c-1.103 0-2 .897-2 2h-2v-3h-4v-2h1c1.654 0 3-1.346 3-3v-3.382l4-2v-7.729c1.235-1.263 2-2.988 2-4.889 0-3.519-2.614-6.432-6-6.92v-1.08c0-1.654-1.346-3-3-3h-17v5.847l3.493.582c1.45.242 2.503 1.483 2.506 2.952l-2.763-1.381h-22l-4-2h-11.236v26h11.236l4-2h22l2.764-1.382v.382c0 1.654 1.346 3 3 3h1v2h-4v10.795l-5.786 13.5c-.142.333-.214.685-.214 1.046 0 1.466 1.193 2.659 2.659 2.659h1.279c1.033 0 1.981-.606 2.415-1.545l3.647-7.901v6.787c0 1.466 1.193 2.659 2.659 2.659h.682c1.466 0 2.659-1.193 2.659-2.659v-6.787l3.646 7.902c.434.938 1.382 1.544 2.416 1.544h1.279c1.466 0 2.659-1.193 2.659-2.659 0-.36-.072-.712-.215-1.048l-5.785-13.498v-1.795h2c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-6c0-1.103-.897-2-2-2zm-11.889-19c1.037 1.014 2.387 1.704 3.889 1.92v.08h-6v-2zm3.889 10h-6v-2h6zm2-2.618v-5.462c.706-.102 1.378-.308 2-.605v5.067zm4-12.382c0 2.757-2.243 5-5 5s-5-2.243-5-5 2.243-5 5-5 5 2.243 5 5zm-20.179-6.543-1.821-.304v-2.153h15c.552 0 1 .449 1 1v1.038c-4.061.306-7.736 2.465-10 5.793v-.442c0-2.455-1.757-4.529-4.179-4.932zm-9.821 19.543h-6v-2h6zm0-4h-6v-2h6zm0-4h-6v-2h6zm0-4h-6v-2h6zm-6 14h6v2h-6zm8-16h6v18h-6zm-19.236 20h-8.764v-22h8.764l3.236 1.618v18.764zm5.236-20h4v18h-4zm22 17.382v-16.764l2 1v14.764zm4 1.618v-14.787l.665-1.496c.888-1.999 2.317-3.649 4.067-4.815-.464.936-.732 1.985-.732 3.098 0 1.075.251 2.089.685 3h-.685c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h6v2h-6c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h6v1c0 .551-.448 1-1 1h-8c-.552 0-1-.449-1-1zm4 3h2v2h-2zm6 4v1 3h-10v-4zm-13.463 23.617c-.107.233-.343.383-.599.383h-1.279c-.363 0-.659-.296-.659-.659 0-.09.019-.178.053-.26l5.606-13.081h2.341v1.78zm8.804.383h-.682c-.363 0-.659-.296-.659-.659v-13.341h2v13.341c0 .363-.296.659-.659.659zm10.659-.659c0 .363-.296.659-.659.659h-1.279c-.256 0-.491-.15-.599-.383l-5.463-11.837v-1.78h2.341l5.606 13.079c.034.084.053.172.053.262zm-6-15.341h-10v-2h10v1zm2-3v-2h2v2zm6 2h-2v-6h2z" />
        </g>
      </g>
    </svg>
  );
}
