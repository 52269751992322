import React, { useEffect } from "react";
import "../style/App.css";
import "../style/Kontakt.css";
import { motion } from "framer-motion";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default function Kontakt() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(".hidden-text, .hidden");
    hiddenElements.forEach((el) => {
      observer.observe(el);
    });
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0.7 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.35, ease: "easeOut" }}
    >
      <Navbar />
      <div className="kontakt" id="top">
        <h1 className="hidden-text">Kontakt</h1>
      </div>
      <div className="kontakt-section">
        <div className="kontakt-container">
          <div className="bonifacego-map-wrapper">
            <iframe
              title="bonifacego-map"
              className="hidden"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19571.475160937276!2d21.03803893824351!3d52.18097627975142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471932b20b7a9b81%3A0x6647c0471a82c18b!2s%C5%9Awi%C4%99tego%20Bonifacego%2070%2C%2002-761%20Warszawa!5e0!3m2!1sen!2spl!4v1675255179178!5m2!1sen!2spl"
              style={{
                width: "100%",
                height: "100%",
                filter: "grayscale(50%)",
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="kontakt-wrapper">
            <h1 className="hidden">Kajutex Studio</h1>
            <p className="hidden">św. Bonifacego 70</p>
            <p className="hidden">02-936 Warszawa</p>
            <p className="hidden">tel.: 722 119 133</p>
            <p className="hidden">
              <a href="mailto:biuro@kajutexstudio.pl">biuro@kajutexstudio.pl</a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}
