import React from "react";
import "../style/Section3.css";
import "../style/App.css";
import "../style/Kontakt.css";
import LocationIcon from "../Components/LocationIcon";

export default function Section3() {
  return (
    <div className="section-3">
      <h2 className="section-3--h2 hidden">Kontakt:</h2>
      <div className="section-3--container">
        <LocationIcon className="oferta-icons hidden-icons" />
        <div className="section-3--wrapper">
          <h1 className="hidden">Kajutex Studio</h1>
          <p className="hidden">św. Bonifacego 70</p>
          <p className="hidden">02-936 Warszawa</p>
          <p className="hidden">tel.: 722 119 133</p>
          <p className="hidden">
            <a href="mailto:biuro@kajutexstudio.pl">biuro@kajutexstudio.pl</a>
          </p>
        </div>
      </div>
    </div>
  );
}
